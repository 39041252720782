import NavigationIcon from "@mui/icons-material/Navigation";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { format } from 'date-fns';
import { Link } from "react-router-dom";

import DestReqCardIndex from "../Commons/destReqCardIndex";
import { db } from "../firebaseConfig";
import AppLoader from "../layout/Loading";
import NoRecordsFound from "../layout/NoRecordsFound";
import AppSubHeader from "../Commons/appSubHeader";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
const AgentView = ({
  userData,
  reqs,
  setReqs,
  loading,
  getAgentActiveReqs,
  setLoading
}) => {
  const [showAll, setShowAll] = useState(true)
  const [selectedDestination, setSelectedDestination] = useState("")
  const [filteredReqs, setFilteredReqs] = useState(reqs);
  useEffect(() => {
    let unsubscribe = () => { };
    const getReqs = async () => {
      unsubscribe = await getAgentActiveReqs();
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    };
    getReqs();

    return unsubscribe();
  }, []);
  const handleShowAllChange = (event) => {
    setShowAll(event.target.checked)
    if (event.target.checked) {
      setSelectedDestination("")
    }
  }
  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSelectedDestination(value)
  }
  useEffect(() => {
    if (showAll) {
      setFilteredReqs(reqs);
    } else {
      setFilteredReqs(
        reqs.filter((req) =>
          req.destination.toLowerCase().includes(selectedDestination.toLowerCase())
        )
      );
    }
  }, [showAll, selectedDestination, reqs]);
  console.log(reqs, "req")
  return (
    <>
      <AppSubHeader name="Your Active Requirements" />
      {loading && <AppLoader />}
      <TextField
        label="Search By Destination"
        // value={searchTerm}
        onChange={handleSearchChange}
        disabled={showAll}
        variant="outlined"
        margin="normal"
      />
      <FormControlLabel
        sx={{ marginLeft: "15px", marginTop: "20px" }}
        control={<Checkbox checked={showAll} onChange={handleShowAllChange} />}
        label="Show All Requests"
      />
      <Grid
        container
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {reqs.length <= 0 && !loading && <NoRecordsFound />}

        {filteredReqs.map((req) => {
          let { id = "" } = req;
          return (
            <Grid item xs={12} sm={4} md={6} key={req?.id}>
              <Link to={`/req/view-bids/${id}`} state={{ req }}>
                <DestReqCardIndex reqData={req} itemId={id} />
              </Link>
            </Grid>
          );
        })}
      </Grid>

      <Fab
        variant="extended"
        color="primary"
        href={"/post-req"}
        sx={{ position: "fixed", bottom: 70, right: 20 }}
      >
        <NavigationIcon sx={{ mr: 1 }} />
        Post Req
      </Fab>
    </>
  );
};

export default AgentView;
