export const countryCodes = [
  { country: 'United States', code: '+1' },
  { country: 'Canada', code: '+1' },
  { country: 'United Kingdom', code: '+44' },
  { country: 'India', code: '+91' },
  { country: 'Australia', code: '+61' },
  { country: 'Germany', code: '+49' },
  { country: 'France', code: '+33' },
  { country: 'Italy', code: '+39' },
  { country: 'Spain', code: '+34' },
  { country: 'Brazil', code: '+55' },
  { country: 'South Africa', code: '+27' },
  { country: 'Japan', code: '+81' },
  { country: 'China', code: '+86' },
  { country: 'Mexico', code: '+52' },
  { country: 'Russia', code: '+7' },
  { country: 'Argentina', code: '+54' },
  { country: 'South Korea', code: '+82' },
  { country: 'Saudi Arabia', code: '+966' },
  { country: 'Egypt', code: '+20' },
  { country: 'Indonesia', code: '+62' },
  { country: 'Turkey', code: '+90' },
  { country: 'Nigeria', code: '+234' },
  { country: 'Pakistan', code: '+92' },
  { country: 'Thailand', code: '+66' },
  { country: 'United Arab Emirates', code: '+971' },
  { country: 'Vietnam', code: '+84' },
  { country: 'Colombia', code: '+57' },
  { country: 'Poland', code: '+48' },
  { country: 'Ukraine', code: '+380' },
  { country: 'Malaysia', code: '+60' },
  { country: 'Singapore', code: '+65' },
  { country: 'Philippines', code: '+63' },
  { country: 'Chile', code: '+56' },
  { country: 'Finland', code: '+358' },
  { country: 'Sweden', code: '+46' },
  { country: 'Norway', code: '+47' },
  { country: 'Denmark', code: '+45' },
  { country: 'Netherlands', code: '+31' },
  { country: 'Belgium', code: '+32' },
  { country: 'Switzerland', code: '+41' },
  { country: 'Austria', code: '+43' },
  { country: 'Greece', code: '+30' },
  { country: 'Portugal', code: '+351' },
  { country: 'Ireland', code: '+353' },
  { country: 'New Zealand', code: '+64' },
  { country: 'Czech Republic', code: '+420' },
  { country: 'Romania', code: '+40' },
  { country: 'Hungary', code: '+36' },
  { country: 'Israel', code: '+972' },
  { country: 'Kuwait', code: '+965' },
  { country: 'Qatar', code: '+974' },
  { country: 'Bahrain', code: '+973' },
  { country: 'Oman', code: '+968' },
  { country: 'Iraq', code: '+964' },
  { country: 'Jordan', code: '+962' },
  { country: 'Lebanon', code: '+961' },
  { country: 'Yemen', code: '+967' },
  { country: 'Sri Lanka', code: '+94' },
  { country: 'Nepal', code: '+977' },
  { country: 'Bangladesh', code: '+880' },
  { country: 'Kazakhstan', code: '+7' },
  { country: 'Uzbekistan', code: '+998' },
  { country: 'Turkmenistan', code: '+993' },
  { country: 'Kyrgyzstan', code: '+996' },
  { country: 'Tajikistan', code: '+992' },
  { country: 'Mongolia', code: '+976' },
  { country: 'Cambodia', code: '+855' },
  { country: 'Laos', code: '+856' },
  { country: 'Myanmar', code: '+95' },
  { country: 'Nepal', code: '+977' },
  { country: 'Syria', code: '+963' },
  { country: 'Vietnam', code: '+84' },
  { country: 'Algeria', code: '+213' },
  { country: 'Morocco', code: '+212' },
  { country: 'Tunisia', code: '+216' },
  { country: 'Kenya', code: '+254' },
  { country: 'Uganda', code: '+256' },
  { country: 'Tanzania', code: '+255' },
  { country: 'Zimbabwe', code: '+263' },
  { country: 'Malawi', code: '+265' },
  { country: 'Zambia', code: '+260' },
  { country: 'Botswana', code: '+267' },
  { country: 'Mauritius', code: '+230' },
  { country: 'Seychelles', code: '+248' },
  { country: 'Madagascar', code: '+261' },
  { country: 'Reunion', code: '+262' },
  { country: 'Ethiopia', code: '+251' },
  { country: 'Rwanda', code: '+250' },
  { country: 'Burundi', code: '+257' },
  { country: 'Gabon', code: '+241' },
  { country: 'Ghana', code: '+233' },
  { country: 'Ivory Coast', code: '+225' },
  { country: 'Liberia', code: '+231' },
  { country: 'Sierra Leone', code: '+232' },
  { country: 'Gambia', code: '+220' },
  { country: 'Mauritania', code: '+222' },
  { country: 'Angola', code: '+244' },
  { country: 'Mozambique', code: '+258' },
  { country: 'Namibia', code: '+264' },
  { country: 'Eswatini', code: '+268' },
  { country: 'Lesotho', code: '+266' },
  { country: 'Cape Verde', code: '+238' },
  { country: 'Saint Kitts and Nevis', code: '+1-869' },
  { country: 'Saint Lucia', code: '+1-758' },
  { country: 'Saint Vincent and the Grenadines', code: '+1-784' },
  { country: 'Grenada', code: '+1-473' },
  { country: 'Barbados', code: '+1-246' },
  { country: 'Trinidad and Tobago', code: '+1-868' },
  { country: 'Antigua and Barbuda', code: '+1-268' },
  { country: 'Saint Pierre and Miquelon', code: '+508' },
  { country: 'Dominica', code: '+1-767' },
  { country: 'Bermuda', code: '+1-441' },
  { country: 'Montserrat', code: '+1-664' },
  { country: 'British Virgin Islands', code: '+1-284' },
  { country: 'Cayman Islands', code: '+1-345' },
  { country: 'Bonaire', code: '+599' },
  { country: 'Curacao', code: '+599' },
  { country: 'Aruba', code: '+297' },
  { country: 'Sint Maarten', code: '+1-721' }
];
