/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import React, { useEffect, useMemo, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";

import { useNavigate, useLocation } from "react-router-dom";
import FullScreenAppPopup from "../Commons/fullScreenAppPopup";
import MyDailog from "../Commons/myDailog.jsx";
import Navbar from "../Navbar";
import { MainContext, devConsolelog, isEmptyObject } from "../Utility";
import KycPending from '../SignUp/kycPending';
import { auth, db } from "../firebaseConfig.js";
import SnackbarMsg from "../Commons/snackbarMsg";

const RedirectComponent = () => {
  let location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // window.location = "/login";
    navigate("/login", { state: { loginRedirectUrl: location.pathname } });
  }, []);
};

function PrivateRoute({ children, authed = false, props }) {
  if (typeof window !== "undefined") {
    console.log("PrivateRoute - ", props, authed);
    if (authed) return <>{children}</>;
    else return <RedirectComponent />;
  } else {
    return null;
  }
}

// check for the last day when kyc pop up was shown, show only once a day. 
const lastKycShowTime = localStorage.getItem("kycPopUpTime");
const checkShowKycPop = ( new Date(Date.now()).getDate() - new Date(Number(lastKycShowTime || Date.now())).getDate() ) >= 1 ;
if(lastKycShowTime === null) localStorage.setItem("kycPopUpTime", Date.now());
console.log("checkShowKycPop", checkShowKycPop, new Date(Date.now()).getDate(), new Date(Number(localStorage.getItem("kycPopUpTime") || Date.now())).getDate());

const AppLayout = ({ children, showNavBar = true }) => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const isUserAgent = Boolean(userData?.isAgent);
  const isLoggedIn = Boolean(userData); // !isEmptyObject(userData);
  const [showSnackbar, setShowSnackbar] = useState({ open: false });

  const [showAgentKyc, setAgentKyc] = useState(checkShowKycPop);
  devConsolelog("AppLayout render ", showAgentKyc)
  // const IS_KYC_POPUP_SKIPPED =
  //   localStorage.getItem("kyc-popup-skip") === "true" || false;

  useEffect(() => {
    let { phone = '' } = userData || {};
    if (!phone) {
      console.warn("Phone number is not available in userData.");
      return;
    }

    const userDocRef = doc(db, "userDetails", phone);

    const unsubscribe = onSnapshot(
      userDocRef,
      (doc) => {
        if (!doc.exists()) {
          // window.alert("No matching documents.");
          return;
        }

        const newUserData = doc.data();
        console.log("user kyc ", phone, newUserData.isKycVerified, userData?.isKycVerified);
        if(newUserData.isKycVerified != userData?.isKycVerified) {
          delete newUserData["adminRemarks"];
          delete newUserData["kycDocs"];
          localStorage.setItem('user', JSON.stringify(newUserData));
          if(newUserData.isKycVerified) setShowSnackbar({
            message: "Your Account is now Verified!",
            open: true,
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
        }
      },
      (error) => {
        console.error("Error fetching activities:", error);
      }
    );

    return () => unsubscribe();
  }, [userData]);

  useEffect(() => {
		const fetchUserIdToken = async () => {
			try {
				auth.onAuthStateChanged(async (user) => {
					if (user) {
						// user.getIdToken().then(function(data) {
						//   console.log(data)
						// });
						let signedInIdToken = await auth.currentUser.getIdToken(
							/* forceRefresh */ true,
						);
						console.log("signedInIdToken ", signedInIdToken, typeof signedInIdToken);
						localStorage.setItem('user', JSON.stringify({ ...userData, firebaseIdToken: signedInIdToken }));
            userData['firebaseIdToken'] = signedInIdToken;
					}
				});
			} catch (e) {
				console.log("signedInIdToken error ", e);
			}
		};

		if(userData?.phone) fetchUserIdToken();
	}, [userData]);

  const contextValue = useMemo(
    () => ({
      userData,
      isUserAgent,
      isLoggedIn,
      showKycPopup: setAgentKyc
    }),
    [userData, isUserAgent, isLoggedIn]
  );

  const navigate = useNavigate();

  const handleClose = () => {
    devConsolelog("close kyc popup", showAgentKyc, checkShowKycPop);
    setAgentKyc(false);
  };

  useEffect(() => {
    if(!Boolean(userData)) return;
    console.log('show kyc popup 1', showAgentKyc, checkShowKycPop, !userData?.isKycVerified);
    // if (!isUserAgent && !userData.isKycVerified)
    //   navigate("/kyc-pending");
    // else 
    if (!userData.isKycVerified && showAgentKyc ) {
      setAgentKyc(showAgentKyc);
      // if(checkShowKycPop) {
        try {
          localStorage.setItem("kycPopUpTime", Date.now());
        } catch (e) {
          console.log("localStorage kycPopUpTime error, e")
        }
      // }
    }

  }, [userData]);

  console.log('layout render ', userData, isLoggedIn);
  return (<>
    {/** (<FullScreenAppPopup handleClose={handleClose} open={showAgentKyc} />) **/}

    <PrivateRoute authed={isLoggedIn}>
      <MainContext.Provider value={contextValue}>
        <div id="myDiv" className="animate-bottom">
          {showNavBar && <Navbar />}
          <Container maxWidth="md" fixed>
            <Box component="main" sx={{ pt: 9, pb: 5 }}>
              {children}
            </Box>
          </Container>
        </div>
      </MainContext.Provider>
    </PrivateRoute>
    {showAgentKyc && <MyDailog showDailog={showAgentKyc} body={<KycPending />} handleClose={handleClose} />}
    {showSnackbar && (
        <SnackbarMsg
          open={showSnackbar.open}
          message={showSnackbar.message}
          anchorOrigin={showSnackbar.anchorOrigin}
          severity={showSnackbar.severity || "success"}
          onClose={() => setShowSnackbar({ open: false })}
        />
      )}
  </>);
};

export default AppLayout;
