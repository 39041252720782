import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import { collection, doc, setDoc, getDoc } from "firebase/firestore";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuItem from '@mui/material/MenuItem';
import Select from "@mui/material/Select";
import LoadingButton from "../Commons/LoadingButton";
import CopyrightFooter from "../Commons/copyright-footer";
import SnackbarMsg from "../Commons/snackbarMsg";
import {
  DestinationNames,
  KYC_PENDING_LINK,
  WHATSAPP_FORUM_LINK_AGENT,
  WHATSAPP_FORUM_LINK_SUPPLIER,
  OFFICIAL_WA_LINK
} from "../Constants";
import { AutocompleteInput, isEmptyObject, sendEmail } from "../Utility";
import { db } from "../firebaseConfig";
import CabEasyLogo from "../utills/logoImgBox";
import { countryCodes } from "../Commons/Country";
const backgroundBanner = "https://firebasestorage.googleapis.com/v0/b/cabeasy-db.appspot.com/o/website%2FHow%20to%20use%20CabEasy%20For%20Travel%20Agents%20(Website).png?alt=media&token=f19577a8-f5a2-43f1-9059-cb37d2808b33";
//require("./signup_banner.png");

const initialFormData = {
  phone: "",
  name: "",
  email: "",
  isAgent: true,
  isSupplier: false,
  isAdmin: false,
  destinations: [],
  isKycVerified: false,
  kycDocs: [],
  status: "pending",
  otherServices: {},
};

// const MENU_ITEM_HEIGHT = 48;
// const MENU_ITEM_PADDING_TOP = 8;

// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: MENU_ITEM_HEIGHT * 4.5 + MENU_ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

export default function SignUp() {
  const [userType, setUserType] = useState("agent");
  const [signupFields, setSignupFields] = useState(initialFormData);
  const [signupLoading, setSignupLoading] = useState(false);
  const [signupAlert, setSignupAlert] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const navigate = useNavigate();
  const [code, setCode] = useState('+91');

  const checkUserExists = async () => {
    if(!signupFields.phone) return;
    let userDataSnap = await getDoc(doc(db, "userDetails", `${code}${signupFields.phone}`));
    if(userDataSnap.exists()) {
      navigate(`/login?phone=${signupFields.phone}&code=${code}`);
      return true
    } else return false
  }

  const handleChange = (field, val) => {
    console.log("signup handleChange ", field, val, isNaN(val), typeof val);
    let tempData = { ...signupFields };
    if (field === "userType") {
      tempData["isAgent"] = val === "agent";
      tempData["isSupplier"] = val === "supplier";
    } else if (field === "destinations") {
      const destVals = typeof val === "string" ? val.split(",") : val;
      // let destData = {};
      // destVals.forEach((item, i) => {
      //   destData[item] = true;
      // });
      tempData[field] = destVals;
    } else if (field === "phone") {
      console.log("signup phone ", val, isNaN(val));
      if (isNaN(val)) return;
      tempData[field] = val.trim();
    } else tempData[field] = val.trim();
    // tempData['isAgent'] = (val == 'supplier');
    setSignupFields(tempData);
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    setSignupLoading(true);
    let userAlreadyExists = await checkUserExists();
    if (userAlreadyExists) navigate("/login");
    let finalFormData = { ...signupFields };
    if (
      !signupFields.phone ||
      isNaN(signupFields.phone) ||
      !(signupFields.phone.length == 10) ||
      (userType === "supplier" && signupFields.destinations.length == 0)
    ) {
      console.log(
        "validation sign up 1 ",
        !signupFields.phone,
        isNaN(signupFields.phone),
        !(signupFields.phone.length == 10),
        userType === "supplier" && signupFields.destinations.length == 0
      );
      setSignupAlert(true);
      setSignupLoading(false);
      return;
    }
    let finalPhone = `${code}${signupFields.phone}`;
    finalFormData["phone"] = finalPhone;
    finalFormData["createdAt"] = Date.now();
    finalFormData["userId"] = `U-${nanoid()}`;
    if (!isEmptyObject(finalFormData["otherServices"])) {
      let finalOtherServies = Object.keys(
        finalFormData["otherServices"]
      ).filter((i) => finalFormData["otherServices"][i]);
      finalFormData["otherServices"] = finalOtherServies;
    } else finalFormData["otherServices"] = [];
    let agentRequiredFields = [
      finalPhone,
      finalFormData.name,
      finalFormData.email,
    ];
    let supplierRequiredFields = [
      finalPhone,
      finalFormData.name,
      finalFormData.email,
      finalFormData.destinations,
    ];
    const isValid = (
      userType === "supplier" ? supplierRequiredFields : agentRequiredFields
    ).every((value) => value.length > 0);
    console.log(
      "validation sign up 2",
      isValid,
      finalPhone,
      finalFormData.name,
      finalFormData.email,
      finalFormData.destinations
    );

    if (!isValid) {
      setSignupAlert(true);
      setSignupLoading(false);
    } else {
      let docRef = doc(collection(db, "userDetails"), finalPhone);
      await setDoc(docRef, finalFormData);
      console.log("signup done ", finalPhone, signupFields.phone);

      await sendEmail({
        to: finalFormData.email,
        name: finalFormData.name,
        template: {
          name: "welcome_signup",
          data: {
            name: finalFormData.name,
            kycLink: KYC_PENDING_LINK,
            whatsappForumLink:
              userType === "agent"
                ? WHATSAPP_FORUM_LINK_AGENT
                : WHATSAPP_FORUM_LINK_SUPPLIER,
          },
        },
      });
      
      setTimeout(() => {
        setSignupLoading(false);
        navigate(`/login?phone=${signupFields.phone}&code=${code}`);
        // if (userType === "agent") navigate("/post-req");
        // else navigate("/my-reqs");
      }, 1000);
    }
  };

  const handleSelectChange = (e) => {
    let val = e.target.value;
    setUserType(val);
    handleChange("userType", val);
  };

  const handleOtherServicesChange = (event) => {
    let otherServices = {
      ...signupFields["otherServices"],
      [event.target.name]: event.target.checked,
    };
    let tempData = { ...signupFields };
    tempData["otherServices"] = otherServices;
    setSignupFields(tempData);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sign Up for Verified B2B Cab Quotes in 15 Minutes | CabEasy </title>
        <meta
          name="description"
          content="Get verified B2B cab quotes within 15 minutes from CabEasy for destinations including Himachal, Kashmir, Rajasthan, Kerala, Karnataka, Tamil Nadu, Uttarakhand (excluding Char Dham), and Andaman and Nicobar Islands. Visit cabeasy.in. Enjoy ₹200 off on your next booking if we miss the mark! (Terms apply: Requirements sent between 10am and 10pm only)."
        />
        <link rel="canonical" href="https://cabeasy.in/signup" />
      </Helmet>

      <Grid container component="main" sx={{ height: "100vh" }}>
        <SnackbarMsg
          open={signupAlert}
          message={"Please fill the required information correctly."}
          severity="error"
        />
        <CssBaseline />

        <Grid
          item
          xs={false}
          sm={4}
          md={8}
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/cabeasy-db.appspot.com/o/website%2FsignupWhiteBoard2.png?alt=media&token=79f911ef-17bd-43bd-9b13-c8b01a9e5f9b"
            height="100%" width="100%" style={{ objectFit: "cover" }}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={4} elevation={7} square component={isMobile ? '' : Paper}>
          <Box
            sx={{
              my: 2,
              py: 5,
              mx: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CabEasyLogo />
            <Typography
              component="h5"
              variant="h5"
              style={{
                fontSize: 17,
                marginTop: "1em",
                fontWeight: "600",
                textDecoration: "underline",
              }}
            >
              Sign up
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSignup}
              sx={{ mt: 3, px: 2 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <center>
                    <ToggleButtonGroup
                      color="primary"
                      value={userType}
                      exclusive
                      onChange={handleSelectChange}
                      aria-label="Platform"
                    >
                      <ToggleButton value="agent">Agent</ToggleButton>
                      <ToggleButton value="supplier">Supplier</ToggleButton>
                    </ToggleButtonGroup>
                  </center>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: "flex" }}>
                    <Select
                      defaultValue={'+91'}
                      label="Country Code"
                      onChange={e => setCode(e.target.value)}
                      sx={{
                        border: 'none',
                        outline: 'none',
                        justifyContent: "left"
                      }}
                    >
                      {countryCodes.map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          {country.code}
                        </MenuItem>
                      ))}
                    </Select>
                    <TextField
                      id="phone"
                      label="Phone"
                      fullWidth
                      placeholder="1234567890"
                      variant="outlined"
                      required
                      autoFocus
                      onChange={(e) => handleChange("phone", e.target.value)}

                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="name"
                    name="Name"
                    required
                    fullWidth
                    id="name"
                    type="text"
                    label="Name"
                    onChange={(e) => handleChange("name", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    onChange={(e) => handleChange("email", e.target.value)}
                  />
                </Grid>
                {userType === "supplier" && (
                  <Grid item xs={12}>
                    <AutocompleteInput
                      label="Destinations*"
                      onChange={(e) =>
                        handleChange("destinations", e.target.value)
                      }
                      value={signupFields["destinations"]}
                      multiSelect={true}
                      values={DestinationNames}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl
                    sx={{ m: 1 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormLabel component="legend">
                      Your Other Services? (We will promote these services after
                      every booking)
                    </FormLabel>
                    <FormGroup>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  signupFields["otherServices"]["Sightseeing"]
                                }
                                onChange={handleOtherServicesChange}
                                name="Sightseeing"
                              />
                            }
                            label="Sightseeing"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  signupFields["otherServices"]["Packages"]
                                }
                                onChange={handleOtherServicesChange}
                                name="Packages"
                              />
                            }
                            label="Packages"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  signupFields["otherServices"]["Hotels"]
                                }
                                onChange={handleOtherServicesChange}
                                name="Hotels"
                              />
                            }
                            label="Hotels"
                          />
                        </Grid>
                      </Grid>
                    </FormGroup>
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <LoadingButton
                loading={signupLoading}
                type="submit"
                sx={{ mt: 3, mb: 2, width: "20em" }}
              >
                Sign Up
              </LoadingButton>

              <Grid container sx={{ mt: 4 }} justifyContent="center">
                <Grid item>
                  <Link href="/login" variant="body2" style={{ color: "#000" }}>
                    Already have an account?{" "}
                    <span style={{ fontWeight: "bold" }}>Sign in</span>
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    href={OFFICIAL_WA_LINK}
                    variant="body2"
                    sx={{
                      textAlign: "center",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    {"Talk to us directly:"}
                    <>&nbsp;</>{" "}
                    <span style={{ fontWeight: "bold" }}>Connect on WhatsApp</span>
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <CopyrightFooter sx={{ mt: 8 }} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
