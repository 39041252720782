import Typography from "@mui/material/Typography";
// import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import DestReqCardIndex from "../Commons/destReqCardIndex";
import AppLoader from "../layout/Loading";
import NoRecordsFound from "../layout/NoRecordsFound";
import AppSubHeader from "../Commons/appSubHeader";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
const SupplierView = ({
  userData,
  reqs,
  setReqs,
  loading,
  getAllActiveReqs,
  setLoading
}) => {
  const [showAll, setShowAll] = useState(true)
  const [selectedDestination, setSelectedDestination] = useState("")
  const [filteredReqs, setFilteredReqs] = useState(reqs);
  useEffect(() => {
    let unsubscribe = () => { };
    const getReqs = async () => {
      unsubscribe = await getAllActiveReqs();
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    };
    getReqs();

    return unsubscribe();
  }, []);
  const handleShowAllChange = (event) => {
    setShowAll(event.target.checked)
    if (event.target.checked) {
      setSelectedDestination("")
    }
  }
  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSelectedDestination(value)
  }
  useEffect(() => {
    if (showAll) {
      setFilteredReqs(reqs);
    } else {
      setFilteredReqs(
        reqs.filter((req) =>
          req.destination.toLowerCase().includes(selectedDestination.toLowerCase())
        )
      );
    }
  }, [showAll, selectedDestination, reqs]);

  return (
    <>
      <AppSubHeader name="Your Active Requirements" />

      {loading && <AppLoader />}
      <Box display="flex" flexDirection="row">
        <TextField
          label="Search By Destination"
          // value={searchTerm}
          onChange={handleSearchChange}
          disabled={showAll}
          variant="outlined"
          margin="normal"
        />
        <FormControlLabel
          sx={{ marginLeft: "15px", marginTop: "20px" }}
          control={<Checkbox checked={showAll} onChange={handleShowAllChange} />}
          label="Show All Requests"
        />
      </Box>
      <Grid
        container
        spacing={{ xs: 1, md: 1 }}
        columns={{ xs: 2, sm: 8, md: 12 }}
      >
        {reqs.length <= 0 && !loading && <NoRecordsFound />}

        {filteredReqs.map((req) => {
          let { id = "" } = req;
          return (
            <Grid item xs={12} sm={6} md={6} key={req?.id}>
              <Link to={`/req/post-bid/${id}`} state={{ req }}>
                <DestReqCardIndex reqData={req} itemId={id} />
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default SupplierView;
