import { db } from "../firebaseConfig";
import { collection, getDoc, doc } from "firebase/firestore";

export const fetchData = async (userData = {}, isAgent = true) => {
  if (!userData?.phone) return null;

  const userStatsRef = doc(db, "userStatistics", userData.phone);
  const userStatsDoc = await getDoc(userStatsRef);

  if (!userStatsDoc.exists()) {
    console.log("No statistics found for the user.");
    return null;
  }

  const userStats = userStatsDoc.data();

  let totalBookingAmount = userStats.totalBookingAmount || 0;
  let totalReqsPosted = userStats.totalReqsPosted || 0;
  let totalBidsPosted = userStats.totalBidsPosted || 0;
  let totalNewSuppliers = userStats.totalSuppNetwork || 0;
  let totalNewAgents = userStats.totalAgentsNetwork || 0;
  let totalBookings = userStats.totalBookings || 0;
  let overallRating = 0;

  if(userData?.ratings && userData?.ratings?.overallRating) overallRating = userData?.ratings?.overallRating;
  if (isAgent) {
    // const userDetailsRef = doc(db, "userDetails", userData.phone);
    // const userDetailsDoc = await getDoc(userDetailsRef);
    // if (userDetailsDoc.exists()) {
    //   const userDetails = userDetailsDoc.data();
    //   overallRating = userDetails.overallRating || 0;
    // }

    return {
      totalBookingAmount,
      totalNewSuppliers,
      totalReqsPosted,
      totalBidsPosted,
      overallRating,
      totalBookings,
    };
  } else {
    return {
      totalBookingAmount,
      totalNewAgents,
      totalBidsPosted,
      totalBookings,
    };
  }
};
